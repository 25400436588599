<template>
  <div class="my-course" v-if="!isCalendar">

    <div class="tab_course">
      <div class="tab_top">
        <div class="top_left">
          <div class="top_name paid_course"
               :class="viewType === 'paid'?'top_name-checked':''"
               @click="chooseTab('paid')">付费课
          </div>
        </div>
        <div class="top_right">
          <div class="top_name community_course"
               :class="viewType === 'community'?'top_name-checked':''"
               @click="chooseTab('community')">公益课
          </div>
          <router-link to="/user/course/expired" class="top_name expired_course">过期课</router-link>
        </div>
      </div>
      <div class="tab_content">
        <community v-if="viewType === 'community'" @judgeTab="judgeTab" :isJudge="isJudge"></community>
        <paid-course v-else @judgeTab="judgeTab"></paid-course>
      </div>
    </div>


  </div>
  <div v-else>
    <PapaCalendar :calendarData="calendarData" @getCalendarData="getCalendarData"
                  @changeView="changeView"></PapaCalendar>
  </div>
</template>

<script>
  import MyCourseJs from './MyCourse.js'

  export default MyCourseJs
</script>

<style lang="less">
  .my-course {
    position: relative;

    .tab_course {
      width: calc(100% - 60px);
      margin: 0 30px;

      .tab_top {
        width: 100%;
        box-sizing: border-box;
        padding: 18px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #e6e6e6;
        margin-bottom: 20px;
        align-items: center;

        .top_name {
          font-size: 16px;
          color: #73797F;
          font-weight: bold;
          cursor: pointer;
        }

        .top_name:hover {
          color: @papaGreen;

        }

        .top_name-checked {
          font-size: 20px;
          color: #333;
        }

        .top_name-checked:hover {
          color: #333;
        }

        .top_left {

        }

        .top_right {
          display: flex;
          align-items: center;
          .community_course {
            margin-right: 78px;
          }
        }
      }
    }

    & > .calendar {
      position: absolute;
      right: 5%;
      top: 27px;
      cursor: pointer;
    }

    & > .calendar:hover {
      color: #29D087;
    }
  }


</style>
