import { formatMoment, translateTag } from '@/utils/utils'
import { mapState } from 'vuex'

export default {
  data () {
    return {
      table: {
        size: {
          row: 6,
          column: 7
        },
        week: ['一', '二', '三', '四', '五', '六', '日',]
      },
      currentTime: '',
      currentYear: '',
      currentMonth: '',
      currentDate: '',
      leapYear: '',
      firstDayofWeek: '',
      daysInMonth: [],
      showLastMonthDays: [],
      showNextMonthDays: [],
      showCurrentMonthDays: [],
      viewDays: [],
      course: [],
      isShowMonthBox: false,
      monthData: [
        {
          name: '一月',
          id: 0
        },
        {
          name: '二月',
          id: 1
        },
        {
          name: '三月',
          id: 2
        },
        {
          name: '四月',
          id: 3
        },
        {
          name: '五月',
          id: 4
        },
        {
          name: '六月',
          id: 5
        },
        {
          name: '七月',
          id: 6
        },
        {
          name: '八月',
          id: 7
        },
        {
          name: '九月',
          id: 8
        },
        {
          name: '十月',
          id: 9
        },
        {
          name: '十一月',
          id: 10
        },
        {
          name: '十二月',
          id: 11
        },
      ],
      showTimeLine: false,
      activeDate: ''
    }
  },
  computed: {
    ...mapState({
      calendarData: state => state.calendar.calendarData
    })
  },
  mounted () {
    this.currentTime = new Date()
    this.currentYear = this.currentTime.getFullYear()
    this.currentMonth = this.currentTime.getMonth()
    this.currentDate = this.currentTime.getDate()
    this.isLeapYear(this.currentYear)
    this.getDaysInMonth()
    this.getFirstDayOfMonth()
    this.calcDays(this.currentMonth)
  },
  methods: {
    formatMoment,
    isLeapYear (year) {
      return this.leapYear = (year % 400 === 0) || ((year % 4 === 0) && (year % 100 !== 0))
    },
    getDaysInMonth () {
      return this.daysInMonth = [31, this.isLeapYear(this.currentYear) ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
    },
    getFirstDayOfMonth (month = this.currentMonth, year = this.currentYear) {
      let firstDay = new Date(year, month, 1)
      let day = firstDay.getDay()
      this.firstDayofWeek = day === 0 ? 7 : day
    },
    calcDays (currentMonth, isEmit) {
      let lastMonth = ''
      if (currentMonth === 11) {
        lastMonth = currentMonth - 1
      } else if (currentMonth === 0) {
        lastMonth = 11
      } else {
        lastMonth = currentMonth - 1
      }
      this.showLastMonthDays = []
      this.showCurrentMonthDays = []
      this.showNextMonthDays = []
      let lastMonthDays = this.daysInMonth[lastMonth]
      this.showLastMonthDays = []
      for (let i = lastMonthDays; i > lastMonthDays - Math.abs(1 - this.firstDayofWeek); i--) {
        let obj = {}
        obj.show = i
        if (this.currentMonth == 0) {
          obj.date = `${this.currentYear - 1}-${this.makeUpZero(12)}-${this.makeUpZero(i)}`
        } else {
          obj.date = `${this.currentYear}-${this.makeUpZero(this.currentMonth)}-${this.makeUpZero(i)}`
        }
        obj.data = []
        this.showLastMonthDays.unshift(obj)
      }
      for (let i = 1; i <= this.daysInMonth[currentMonth]; i++) {
        let obj = {}
        if (i === 1) {
          obj.show = `${currentMonth + 1}月`
        } else {
          obj.show = i
        }
        obj.data = []
        obj.date = `${this.currentYear}-${this.makeUpZero(this.currentMonth + 1)}-${this.makeUpZero(i)}`
        this.showCurrentMonthDays.push(obj)

      }
      for (let i = 1; i <= 42 - this.daysInMonth[currentMonth] - this.showLastMonthDays.length; i++) {
        let obj = {}
        if (i === 1) {
          if (currentMonth === 11) {
            obj.show = '1月'
            obj.date = `${this.currentYear + 1}-01-${this.makeUpZero(i)}`
          } else {
            obj.show = `${currentMonth + 2}月`
            obj.date = `${this.currentYear}-${this.makeUpZero(this.currentMonth + 2)}-${this.makeUpZero(i)}`
          }
        } else {
          if (currentMonth === 11) {
            obj.show = i
            obj.date = `${this.currentYear + 1}-01-${this.makeUpZero(i)}`
          } else {
            obj.show = i
            obj.date = `${this.currentYear}-${this.makeUpZero(this.currentMonth + 2)}-${this.makeUpZero(i)}`
          }
        }
        obj.data = []
        this.showNextMonthDays.push(obj)
      }
      let dayArr = this.showLastMonthDays.concat(this.showCurrentMonthDays).concat(this.showNextMonthDays)
      if(isEmit !=='noEmit'){
        this.$emit('getCalendarData', dayArr[0].date, dayArr[41].date)
      }
      this.renderData(dayArr, this.calendarData).then(res => {
        let dateArr = []
        for (let i = 0; i < res.length; i += 7) {
          dateArr.push(dayArr.slice(i, i + 7))
        }
        this.$nextTick(() => {
          this.viewDays = []
          this.viewDays = dateArr
        })
      })

    },
    handleMonthBox () {
      this.isShowMonthBox = true
    },
    changeMonth (month) {
      this.getFirstDayOfMonth(month)
      this.viewDays = []
      this.isShowMonthBox = false
      this.currentMonth = month
      this.getFirstDayOfMonth()
      this.calcDays(month)
    },
    renderData (arr, data) {
      return new Promise((resolve, reject) => {
        data.sort(this.compare('started_at'))
        arr.forEach(item => {
          data.forEach(ele => {
            if (item.date == formatMoment(ele.started_at, 'YYYY-MM-DD')) {
              if (ele.status === 1) {
                ele.color = 'green'

              } else if (ele.status === 2) {
                ele.color = 'red'
              } else {
                ele.color = 'gray'
              }
              let obj = {
                type: '',
                time: `${formatMoment(ele.started_at, 'HH:mm')}-${formatMoment(ele.ended_at, 'HH:mm')}`,
                con: ''
              }
              if (ele.teacher) {
                obj.type = '1v1'
                // obj.con = `${ele.teacher} ${this.translateTag(ele.category_id).name} | ${ele.course_subject} ${ele.id}`
              } else {
                obj.type = '班课'
                // obj.con = `${ele.course.name}`
              }
              ele.show = obj
              item.data.push(ele)
            }
          })
        })
        resolve(arr)
      })

    },
    makeUpZero (num) {
      return num >= 10 ? num : '0' + num
    },
    dClick (day) {
      this.showTimeLine = true
      this.activeDate = day.date
    },
    cancleTimeLine () {
      this.showTimeLine = false
    },
    changeYear (num) {
      this.currentYear = this.currentYear + num * 1
      this.getDaysInMonth()
      this.getFirstDayOfMonth()
    },
    handleLeftBtn () {
      this.$emit('changeView')
    },
    translateTag,
    compare (property) {
      return function (a, b) {
        let value1 = a[property]
        let value2 = b[property]
        return value1 - value2
      }
    }
  },

  watch: {
    calendarData (newObj, oldObj) {
      if (newObj[0] && oldObj[0]) {
        if (newObj[0].id === oldObj[0].id) {
          return
        } else {
          this.calcDays(this.currentMonth,'noEmit')
        }
      } else if (newObj.length === 0) {
        return
      } else {
        this.calcDays(this.currentMonth) // todo month
      }
    }
  }
}
