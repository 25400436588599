<template>
  <div class="user_paid">
    <div class="paid_tab">
      <span class="button button--small"
            :class="viewType==='class'?'button--green':''" @click="changeView('class')">直播班课
      </span>
      <div class="button button--small"
           :class="viewType==='video'?'button--green':''" @click="changeView('video')">视频
      </div>
      <div class="button button--small" :class="viewType==='private'?'button--green':''" @click="changeView('private')">
        1v1
      </div>
     <span class="button button--small" v-show="videoCourseData&&videoCourseData.length>0 ||liveCourseData&&liveCourseData.length>0 || privateCourseData && privateCourseData.length > 0"
           :class="viewType==='material'?'button--green':''" @click="changeView('material')">公共资料
     </span>
    </div>
    <div class="paid_list">
      <template v-if="viewType === 'class'">
        <template v-if="liveCourseData.length>0">
          <template v-for="item in liveCourseData">
            <div class="item" :key="item.id" v-if="item.course.course_mode === 1">
              <div @click="goClassCourse(item)">
                <div class="item_title">
                  <div class="title_course">
             <span
                     v-if="translateTag(item.course.category_id)!=undefined"
                     class="course_tag">{{ translateTag(item.course.category_id).abbr }}</span>
                    <span class="course_type course_type-green">直播</span>
                    <span>                {{ item.course.name }}
</span>
                  </div>
                </div>
                <div class="row">
                  <div class="item_range"><span
                          class="key">直播安排</span>{{ formatMoment(item.plan_started_at, 'YYYY/MM/DD') }} -
                    {{ formatMoment(item.plan_ended_at, 'YYYY/MM/DD') }} <span class="key"></span>
                    共{{ item.total_period }}节
                  </div>
                  <div class="item_status"><span class="key">已完成课时/剩余课时</span> <span
                          style="color: #29d087">{{ item.finished_period }}</span>/{{
                      item.total_period
                      -
                      item.finished_period
                    }}
                  </div>
                </div>
                <div class="row">
                  <div class="item_status"><span class="key">有效期至</span>
                    {{ formatMoment(item.expired_at, 'YYYY/MM/DD') }}
                  </div>
                  <div class="item_status"><span class="key">下次上课</span>
                    {{ formatMoment(item.next_ended_at, 'YYYY/MM/DD') }}
                    {{ formatMoment(item.next_started_at, 'HH:mm') }} - {{ formatMoment(item.next_ended_at, 'HH:mm') }}
                  </div>
                </div>
              </div>
            </div>
          </template>
        </template>
        <template v-else>
          <no-content :data="noContentData" />
        </template>
      </template>
      <template v-else-if="viewType === 'video'">
        <template v-if="videoCourseData.length>0">
          <template v-for="item in videoCourseData">
            <div class="item" :key="item.id" v-if="item.course.course_mode === 3">
              <div @click="goClassCourse(item)">
                <div class="item_title">
                  <div class="title_course">
             <span
                     v-if="translateTag(item.course.category_id).abbr!=undefined"
                     class="course_tag">{{ translateTag(item.course.category_id).abbr }}</span>
                    <span class="course_type course_type-blue">视频</span>
                    {{ item.course.name }}
                  </div>
                </div>
                <div class="item_range" style="color: #666"><span
                        class="key">有效期至</span>{{ formatMoment(item.expired_at, 'YYYY/MM/DD') }}
                </div>
              </div>
            </div>
          </template>
        </template>
        <template v-else>
          <no-content :data="noContentData" />
        </template>
      </template>
      <template v-else-if="viewType === 'private'">
        <template v-if="privateCourseData.length>0">
          <div class="item item-private" v-for="item in privateCourseData" :key="item.id">
            <div @click="goPrivate(item)">
              <div class="item_title">
                <div class="title_course title_course-private">
                <span v-if="translateTag(item.category_id).abbr!=undefined"
                      class="category_tag">{{ translateTag(item.category_id).abbr }}</span>
                  {{ translateTag(item.category_id).name }}{{ item.course_subject }}
                </div>
                <div class="title_teacher">
                  {{ item.teacher }}老师
                </div>

              </div>
              <div class="row">
                <div class="item_range"><span class="key">总课时</span> {{ item.total_period }}</div>
                <div><span class="key">已完成课时/剩余课时 </span><span
                        style="color: #29d087">{{ item.finished_period }}</span>/{{ item.usable_period }}
                </div>
              </div>
              <div class="row">
                <div class="item_status"><span class="key">有效期至</span>
                  {{ formatMoment(item.expired_at, 'YYYY/MM/DD') }}
                </div>
                <div class="item_status"><span class="key">下次上课</span>
                  {{ formatMoment(item.next_ended_at, 'YYYY/MM/DD') }}
                  {{ formatMoment(item.next_started_at, 'HH:mm') }} - {{ formatMoment(item.next_ended_at, 'HH:mm') }}
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <no-content :data="noContentData" />
        </template>
      </template>
      <template v-else>
        <PublicMaterial></PublicMaterial>
      </template>
    </div>
    <papa-pagination :pagination="pagination" @handlePagination="handlePagination"></papa-pagination>
  </div>
</template>

<script>
  import PaidCourseJs from '@/views/User/MyCourse/PaidCourse/PaidCourse.js'

  export default PaidCourseJs
</script>

<style lang="less" scoped>
  .user_paid {
    padding: 5px 20px;

    .paid_tab {
      display: flex;
      margin-bottom: 20px;

      .button {
        margin-right: 10px;
        border-radius: 4px;
        padding: 0 12px;
        width: auto;
      }
    }

    .paid_list {
      overflow: hidden;

      .item {
        width: 100%;
        //height: 100px;
        margin: 0 10px 10px 0;
        padding: 15px 20px;
        border: 1px solid #e6e6e6;
        font-size: 14px;
        text-align: left;
        box-sizing: border-box;
        border-radius: 5px;
        cursor: pointer;

        a {
          color: #333;
        }

        .row {
          display: flex;

          :first-child {
            width: 45%;
          }
        }

        .key {
          margin-right: 1em;
        }

        .item_title {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 12px;

          .title_course {
            color: #333;
          }

          .title_course-private {
            display: flex;
            align-items: center;
          }

          .course_tag {
            background: #667482;
            padding: 1px 5px;
            margin-right: 8px;
            color: #fff;
            font-size: @fontSizeCon;
          }
        }

        .item_range {
          //display: flex;
          margin: 3px 0;

          div {
            //margin-left: 30px;
          }
        }

        .item_status {
          margin: 2px 0;

        }
      }
      //
      //.item-private {
      //  height: 100px;
      //}

      .item:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
</style>
