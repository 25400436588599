import { translateTag, formatMoment } from '@/utils/utils'
import NoContent from '@/components/NoContent/NoContent.vue'
import MaterialTips from './components/tips/tips.vue'
import * as Api from '@/api/user/user'

export default {
  name: 'PublicMaterial',
  components: { NoContent, MaterialTips },
  data () {
    return {
      showTips: false,
      noContentData: {
        pic: 'course_pic',
        tip: '暂时没有资料',
      },
      materialList: [],
      categories: ['', '雅思','托福','GRE','GMAT', '实用英语','PTE','学术英语', 'Duolingo', '考研英语', 'A-Level'],
      categoryList: [],
      currentCategory: 1
    }
  },
  mounted () {
    this.getMaterial()
  },
  computed: {
    currentMaterials: function ( ){
      return this.materialList.filter(item=>item.category_id === this.currentCategory)
    }
  },
  methods: {
    handleDownload(material){
      Api.getCourseMaterialUrl(material.id,(res)=>{
        window.open(res.url)
      }, () => {this.showTips = true})
    },
    getMaterial(){
      Api.publicCourseware((res)=>{
        console.log(res)
        this.materialList = res
        this.getCategoryList(res)
      })
    },
    getCategoryList(list){
      let arr = []
      list.forEach(item=>{
        arr.push(item.category_id)
      })
      this.categoryList = Array.from(new Set(arr))
    },
    handleChangeCategory(category){
      this.currentCategory = category
    },
    closePopUp() {
      this.showTips = false
    },
    translateTag,
    formatMoment
  }
}
