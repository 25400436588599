<template>
  <div class="c_public_material">
    <template v-if="materialList.length> 0">
      <div class="tips">
    <span>      <i class="iconfont icon-papa-tip"></i>
      受服务器限制，每个文件可下载5次， 请在下载后及时妥善保存哦！</span>
      </div>

      <div class="category-tab">
        <div class="tab" v-for="category in categoryList"
             :key="category"
             :class="category === currentCategory?'tab-active': ''"
             @click='handleChangeCategory(category)'
        ><span>{{categories[category]}}</span></div>
      </div>

      <div class="list">
        <div class="item" v-for="material in currentMaterials" :key="material.file_path">
          <div class="left">
            <div class="name_tag">
              <span class="course_tag"></span>
              <span class="title">{{material.filename}}</span>
            </div>
            <div class="time">
              <span class="key">上传时间</span>
              <span class="value">{{formatMoment(material.created_at)}}</span>
            </div>
            <div class="size">
              <span class="key">文件大小</span>
              <span class="file-size" v-if="material.file_size > 1048576">{{(material.file_size/1048576).toFixed(1)}}M</span>
              <span class="file-size" v-else>{{(material.file_size/1024).toFixed(1)}}K</span>
            </div>
          </div>
          <div class="right">
            <div class="button" @click="handleDownload(material)">
              <i></i>下载
            </div>
          </div>
        </div>
      </div>
    </template>
    <NoContent v-else :data="noContentData"></NoContent>
    <el-dialog
            title=" "
            :visible.sync="showTips"
            width="480px"
    >
      <MaterialTips @closePopUp="closePopUp"></MaterialTips>
    </el-dialog>
  </div>
</template>

<script>
  import PublicMaterial from '@/views/User/MyCourse/PublicMaterial/PublicMaterial'

  export default PublicMaterial
</script>

<style lang="less" scoped>
  .c_public_material {
    width: 100%;

    .tips {
      margin: 20px 0 0px;
      text-align: left;

      span {
        padding: 6px 9px;
        border-radius: 4px;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.6);
        background: #F5F5F5;

        .iconfont::before {
          margin-right: 2px;
        }
      }
    }

    .category-tab{
      width: 100%;
      height: 68px;
      display: flex;
      align-items: center;
      text-align: left;
      .tab{
        cursor: pointer;
        margin-right: 40px;
        span{
          position: relative;
        }

      }
      .tab-active{
        font-weight: bold;
        color: #29d087;
        span::after{
          content: '';
          position: absolute;
          left: 50%;
          margin-left: -6px;
          bottom: -6px;
          width: 12px;
          height: 4px;
          border-radius: 20px;
          background: #29D087;

        }
      }
    }

    .list {
      width: 100%;

      .item {
        display: flex;
        align-items: center;
        box-sizing: border-box;
        padding: 14px;
        width: 100%;
        margin-bottom: 12px;
        height: 100px;
        background: #FFFFFF;
        border-radius: 8px 8px 8px 8px;
        opacity: 1;
        border: 1px solid #F5F5F5;

        text-align: left;

        .left {
          flex: 1;
          .title{
            font-weight: bold;
            color: #333333;
          }

          .key {
            color: rgba(0, 0, 0, 0.3);
            margin-right: 10px;
          }

          .value {
            color: rgba(0, 0, 0, 0.6);
          }
        }

        .right {
          width: 100px;

          .button {
            width: 80px;
            height: 38px;
            color: #29d087;
            border: 1px solid #29d087;
            border-radius: 30px;
            text-align: center;
            line-height: 38px;
            cursor: pointer;
          }
        }
      }
    }

    /deep/ .el-dialog {
      border-radius: 8px;
      height: 194px;
      .el-dialog__body{
        padding: 10px 20px;
      }

    }
  }
</style>
