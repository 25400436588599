import LiveCourse from '../Course/Course.vue'
import * as Api from '@/api/user/user'
import { formatMoment, translateTag } from '@/utils/utils'
import { activityType } from '@/enums/API'
import { mapActions } from 'vuex'

export default {
    data () {
        return {
            swiperBtn: false,
            soonCourseSwiperOptions: {
                slidesPerView: 2,
                spaceBetween: 30,
                slidesPerGroup: 2,
                loop: true,
                loopFillGroupWithBlank: true,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                }
            },
            soonCourseData: [],
            oldCourseData: [],
            coursePagination: {}
        }
    },
    props: {
        isJudge: {
            type: Boolean,
            default: true
        }
    },
    mounted () {
        this.getSoonData()
    },
    methods: {
        getSoonData () {
            Api.publicCourse(activityType.publicClass, {is_expired: 0}, res => {
                this.soonCourseData = res
                this.getOldCourseData()
            })
        },
        getOldCourseData (pagination = 1) {
            let params = {
                is_expired: 0,
                page: pagination
            }
            Api.publicCourseHistory(params, (res, coursePagination) => {
                this.oldCourseData = res
                this.coursePagination = coursePagination
                if (this.soonCourseData.length === 0 && this.oldCourseData.length === 0 && this.isJudge) {
                    this.$emit('judgeTab', 'paid')
                }
            })
        },
        goCourseDetail (item) {
            this.setCurrentCourse(item)
            this.$router.push({
                path: `/user/pastCourseDetail/${item.course.id}/${item.id}`
            })
        },
        handlePagination (pagination) {
            this.getOldCourseData(pagination)
        },
        formatMoment,
        translateTag,
        ...mapActions([
            'setCurrentCourse'
        ])
    },
    components: {
        LiveCourse
    }
}
