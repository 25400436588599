<template>
  <div class="calendar">
    <div class="header">
      <div class="left" @click="handleLeftBtn">
        <i class="iconfont icon-papa-rt">课程</i>
      </div>
      <div class="center" @click="handleMonthBox">{{currentYear}}年{{currentMonth+1}}月 <i
          class="iconfont icon-papa-arrLeft"></i>
      </div>
      <div class="right"></div>
      <div class="month-box" v-show="isShowMonthBox">
        <div class="year">
          <div class="last-year" @click="changeYear(-1)">
            <i class="iconfont icon-papa-rt"></i>
          </div>
          <div class="current-year">{{currentYear}}</div>
          <div class="next-year" @click="changeYear(1)">
            <i class="iconfont icon-papa-right"></i>
          </div>
        </div>
        <div class="month" @click="changeMonth(item.id)" v-for="item in monthData" :key="item.name">{{item.name}}</div>
      </div>
    </div>
    <div class="content">
      <div class="week">
        <div class="week-item" v-for="item in table.week" :key="item">{{item}}</div>
      </div>
      <div class="day-box" v-show="viewDays.length>0">
        <div class="row-group" v-for="(week,index) in viewDays" :key="index">
          <div class="day" v-for="day in week" :key="day.date" :data-date="day.date" @click="dClick(day)">
            <div class="top">
              <div class="left" :class="day.data.length>0?'':'visibility'">共{{day.data.length}}节</div>
              <div class="right">{{day.show}}</div>
            </div>
            <div class="con">
              <div class="list" v-for="list in day.data" :key="list.date" >
                  <div :class="list.color" style="background: #fff;margin-right: 5px">{{list.show.time}}</div>
                  <div :class="list.color">{{list.show.type}}</div>
<!--                  <span :class="list.color">{{list.show.con}}</span>-->
              </div>
            </div>
            <div class="time-line" v-show="showTimeLine&&activeDate===day.date&&day.data.length>0">
              <div class="title">{{day.date}}</div>
              <div class="time-line-con">
                <div class="time-line-item" v-for="(item,index) in day.data" :key="index">
                  <div class="top">
                    <div class="point"></div>
                    <div class="timestamp">{{formatMoment(item.started_at,'HH:mm')}}</div>
                  </div>
                  <div class="bottom">
                    <div class="line"></div>
                    <div class="con">
                      <span :class="item.color">{{item.show.type}}</span>
                      <span :class="item.color">{{item.show.con}}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="button" @click.stop="cancleTimeLine">确认</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import CalendarJs from './Calendar'

  export default CalendarJs
</script>

<style scoped lang="less">
  .calendar {
    width: 100%;
    padding: 30px;
    box-sizing: border-box;
    position: relative;

    & > .header {
      display: flex;
      justify-content: space-between;

      & > .left {
        cursor: pointer;
      }

      & > .center {
        cursor: pointer;
      }

      & > .month-box {
        width: 400px;
        height: 280px;
        border-radius: 5px;
        background: #fff;
        position: absolute;
        top: 60px;
        left: 50%;
        margin-left: -200px;
        float: left;
        padding: 20px;
        box-sizing: border-box;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);

        & > .month {
          width: 70px;
          height: 40px;
          padding: 10px;
          float: left;
          text-align: center;
          line-height: 40px;
          cursor: pointer;
        }

        & > .month:hover {
          color: #29D087;
          background: #F4FDF8;
        }

        & > .year {
          height: 40px;
          width: 100%;
          margin-bottom: 15px;
          display: flex;
          align-items: center;
          justify-content: space-around;

          & > .last-year {
            cursor: pointer;
          }

          & > .next-year {
            cursor: pointer;
          }
        }
      }
    }

    & > .content {
      margin-top: 30px;
      width: 889px;
      height: 520px;
      box-sizing: border-box;
      display: table;
      border-collapse: collapse;

      & > .week {
        width: 100%;
        height: 38px;
        display: table;
        border: 1px solid #e6e6e6;
        border-bottom: none;
        box-sizing: border-box;

        & > .week-item {
          display: table-cell;
          line-height: 38px;
        }
      }

      & > .day-box {
        display: table;
        border-collapse: collapse;

        & > .row-group {
          display: table-row-group;

          & > .day {
            box-sizing: border-box;
            border: 1px solid #E6E6E6;
            width: 127px;
            height: 80px;
            display: table-cell;
            text-align: right;
            padding: 10px;

            & > .top {
              width: 100%;
              height: 20px;
              display: flex;
              justify-content: space-between;

              & > .left {
                text-align: left;
                font-size: 12px;
              }

              & > .visibility {
                visibility: hidden;
              }

              & > .right {
                text-align: right;
              }
            }

            & > .con {
              overflow-y: auto;

              & > .list {
                font-size: 12px;
                white-space: nowrap;
                width: 105px;
                overflow-x: hidden;
                cursor: pointer;
                display: flex;
                justify-content: space-between;
              }

              .green {
                color: #309525;
                background: #F4FDF8;
                width: 2em;
                text-align: center;
              }
              .red {
                color: #F84949;
                background: #FDEFEF;
                width: 2em;
                text-align: center;
              }

              .gray {
                background: #F2F2F2;
                color: #999;
              }

            }

            & > .time-line {
              width: 328px;
              height: 360px;
              padding: 30px;
              box-sizing: border-box;
              position: fixed;
              top: 220px;
              left: 50%;
              margin-left: -48px;
              background: #fff;
              z-index: 999;
              box-shadow: 0px 13px 46px 0px rgba(86, 86, 86, 0.05);

              & > .title {
                font-size: 14px;
                color: #555;
                text-align: left;
                width: 100%;
                margin-bottom: 20px;
              }

              & > .time-line-con {
                text-align: left;
                height: 200px;
                overflow: auto;
                & > .time-line-item {
                  & > .top {
                    display: flex;
                    align-items: center;

                    & > .point {
                      width: 5px;
                      height: 5px;
                      background: #29D087;
                      border-radius: 50%;
                      margin-right: 10px;
                    }

                    & > .timestamp {
                      color: #999;
                      font-size: 14px;
                    }
                  }

                  & > .bottom {
                    display: flex;
                    align-items: center;

                    & > .line {
                      width: 1px;
                      height: 40px;
                      background: #F7F7F7;
                      margin-right: 12px;
                      margin-left: 2px;
                    }

                    & > .con {
                      color: #333;
                      font-size: 16px;
                    }
                  }
                }

                & > .time-line-item:last-child {
                  & > .bottom {
                    & > .line {
                      visibility: hidden;
                    }
                  }
                }
              }

              & > .button {
                width: 160px;
                height: 40px;
                background: #29D087;
                border-radius: 3px;
                font-size: 16px;
                color: #fff;
                text-align: center;
                line-height: 40px;
                cursor: pointer;
                position: absolute;
                bottom: 30px;
                left: 50%;
                margin-left: -80px;
              }
            }
          }
        }

      }
    }
  }

</style>
