<template>
  <div class="user_community">
    <div class="soon" v-if="soonCourseData.length>0">
      <div class="soon_title title">直播课</div>
      <div class="soon_course">
        <div class="swiper_box">
          <swiper :options="soonCourseSwiperOptions" ref="courseSwiper" class="swiper-no-swiping">
            <swiper-slide v-for="(course,index) in soonCourseData" :key="index">
              <router-link :to="`/activityDetail/${course.community_group.id}`"
                           style="display: inline-block; width: 100%">
                <div class="course_item">
                  <div class="item_img">
                    <img :src="course.community_group.cover_image_url" alt="">
                  </div>
                  <div class="item_desc">
                    <div class="desc_title">{{course.community_group.title}}</div>
                    <div class="desc_time">
<!--                         {{course.community_group.subtitle}}-->
                        开课时间 {{formatMoment(course.community_group.started_at,'YYYY/M/DD')}}
                    </div>
                  </div>
                </div>
              </router-link>
            </swiper-slide>
          </swiper>
        </div>
        <div class="swiper-button-con swiper-button-prev swiper-button-white"
             :class="swiperBtn?'show':'hide'" slot="button-prev">
        </div>
        <div class="swiper-button-con swiper-button-next swiper-button-white" :class="swiperBtn?'show':'hide'"
             slot="button-next">
        </div>
      </div>
    </div>
    <div class="old">
      <div class="old_title title">往期视频</div>
      <div class="old_course">
        <div class="item" v-for="item in oldCourseData" :key="item.id">
          <div class="item-con" @click="goCourseDetail(item)">
            <div class="img">
              <img :src="item.course.cover_image_url" alt="">
            </div>
            <div class="course-desc">
              <div class="course-title">
              <span
                  v-if="translateTag(item.course.category_id).abbr!=undefined">{{translateTag(item.course.category_id).abbr}}</span>
                {{item.course.name}}
              </div>
              <div class="course-time">
                有效期至 {{formatMoment(item.expired_at,'YYYY/M/DD')}}
<!--                {{item.subtitle}}-->
              </div>
            </div>
          </div>
        </div>
      </div>
      <papa-pagination :pagination="coursePagination" @handlePagination="handlePagination"></papa-pagination>
    </div>
  </div>
</template>

<script>
  import CommunityJs from '@/views/User/MyCourse/Community/Community.js'

  export default CommunityJs
</script>

<style lang="less" scoped>
  .user_community {
    width: 100%;
    box-sizing: border-box;
    padding: 0 18px;

    .soon {
      width: 100%;

      .soon_course {
        width: 100%;
        position: relative;

        .swiper_box {
          width: 800px;
          margin: 0 auto;
        }

        .course_item {
          /*width: 390px;*/
          height: 98px;
          border: 1px solid #E6E6E6;
          border-radius: 5px;
          display: flex;
          box-sizing: border-box;
          /*margin: 0 2px;*/

          .item_img {
            width: 176px;
            min-width: 176px;
            height: 100%;
            border-radius: 5px;
            overflow: hidden;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .item_desc {
            padding-left: 13px;
            text-align: left;

            .desc_title {
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
              font-size: 16px;
              color: #333;
              font-weight: bold;
              margin-top: 12px;
            }

            .desc_time, .desc_teacher {
              font-size: 12px;
              color: #333;
            }

            .desc_time {
              margin: 2px 0;
            }
          }
        }
      }
    }

    .old {
      width: 100%;

      & > .old_title {
        margin-top: 30px;
      }
      .item {

        & > .item-con {
          width: 100%;
          height: 138px;
          display: flex;
          align-items: center;
          cursor: pointer;

          & > .img {
            width: 176px;
            height: 99px;
            margin-right: 20px;
            border-radius: 3px;
            overflow: hidden;

            & > img {
              width: 100%;
              max-height: 99px;
            }
          }

          & > .course-desc {
            text-align: left;
            width: 75%;

            & > .course-title {
              display: flex;
              align-items: center;

              & > span {
                background: #667482;
                color: #fff;
                padding: 2px 4px;
                margin-right: 8px;
                font-size: @fontSizeCon;
              }

              font-size: @fontSizeTitle;
              color: #333;
              font-weight: bold;
            }

            & > .course-time {
              font-size: @fontSizeSubTitle;
              color: #5A5C63;
              margin: 6px 0 30px;
            }

            & > .course-price {
              color: @papaRed;
              font-size: @fontSizeTitle;
              font-weight: 600;
            }

            & > .course-last-line {
              display: flex;
              justify-content: space-between;

              & > .course-enrolls {
                color: #999;
                font-size: @fontSizeCon;
              }

              & > .call-service {
                background: #EDF9F3;
                color: @papaGreen;
                font-size: @fontSizeCon;
                cursor: pointer;
              }
            }


          }
        }

        & > .item-con {
          position: relative;

          & > .mask {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(247, 247, 247, 0.3);
          }

          & > .detail {
            width: 100%;
            height: 138px;
            border-bottom: 1px solid #e6e6e6;
            display: flex;
            align-items: center;
            cursor: pointer;

            & > .img {
              width: 176px;
              height: 99px;
              margin-right: 20px;
              border-radius: 3px;
              overflow: hidden;

              & > img {
                width: 100%;
                height: 100%;
              }
            }

            & > .course-desc {
              text-align: left;
              width: 75%;

              & > .course-title {
                display: flex;
                align-items: center;

                & > span {
                  background: #667482;
                  color: #fff;
                  padding: 2px 4px;
                  margin-right: 8px;
                  font-size: @fontSizeCon;
                }

                font-size: @fontSizeTitle;
                color: #333;
                font-weight: bold;
              }

              & > .course-time {
                font-size: @fontSizeSubTitle;
                color: #5A5C63;
                margin: 6px 0 30px;
              }

              & > .course-price {
                color: @papaRed;
                font-size: @fontSizeTitle;
                font-weight: 600;
              }

              & > .course-last-line {
                display: flex;
                justify-content: space-between;

                & > .course-enrolls {
                  color: #999;
                  font-size: @fontSizeCon;
                }

                & > .call-service {
                  background: #EDF9F3;
                  color: @papaGreen;
                  font-size: @fontSizeCon;
                  cursor: pointer;
                }
              }


            }
          }

        }
      }
    }

    .title {
      width: 100%;
      box-sizing: border-box;
      margin-bottom: 14px;
      text-align: left;
      font-size: 14px;
      color: #333;
      font-weight: bold;
    }

    .swiper-container {
      /*width: 800px;*/

      /deep/ .swiper-wrapper {
        & > .swiper-slide {
          /*width: 390px!important;*/
          margin: 0;
          /*margin-right: 20px!important;*/
        }
      }

    }

    .swiper-button-con {

      .icon-papa-right, .icon-papa-rt {
        font-size: 26px;
      }

    }

    .swiper-button-next {
      right: 0px;
    }

    .swiper-button-prev {
      left: 0;
    }

    .swiper-button-next, .swiper-button-prev {
      outline: none;
      cursor: pointer;
      color: rgba(230, 230, 230, 1);
    }
    .swiper-button-next:after, .swiper-button-prev:after {
      font-size: 16px;
    }
  }
</style>
