<template>
  <div class="public">
    <div class="type">
      <div class="soon" :class="selectType === 'soon'?'is-active':'' " @click="getSoonData">已报名直播</div>
      <div class="past" :class="selectType === 'past'?'is-active':'' " @click="getPastData">往期视频</div>
    </div>
    <div v-if="selectType === 'soon'" class="course-con">
      <div class="item" v-for="(item,index) in publicData" :key="index">
        <router-link v-if="item.community_group_subject"
                     :to="{path:`/activityDetail/${item.community_group_subject.id}`}">
          <div class="img">
            <img :src="item.community_group_subject.cover_image_url" alt="">
          </div>
          <div class="course-desc">
            <div class="course-title">
              <span>{{translateTag(item.community_group_subject.category_id).abbr}}</span>
              {{item.community_group_subject.title}}
            </div>
            <div class="course-time">
              {{item.community_group_subject.subtitle}}
            </div>
            <div class="course-last-line">
              <div class="course-enrolls"></div>
              <div class="call-service">请联系微信小助手参与课程</div>
            </div>
          </div>
        </router-link>
      </div>
      <no-content v-if="publicData.length===0" :data="noContentData"></no-content>
    </div>
    <div v-else-if="publicData" class="course-con">
      <div class="item" v-for="(item,index) in publicData" :key="index">
        <router-link v-if="item.course" :to="{path:`pastCourseDetail/${item.course.id}/${item.id}`}">
          <div class="img">
            <img :src="item.course.cover_image_url" alt="">
          </div>
          <div class="course-desc">
            <div class="course-title">
              <span>{{translateTag(item.course.category_id).abbr}}</span>
              {{item.course.title}}
            </div>
            <div class="course-time">
              {{item.subtitle}}
            </div>
            <div class="course-last-line">
              <div class="course-enrolls"></div>
              <!--              <div class="call-service">请联系微信小助手参与课程</div>-->
            </div>
          </div>
        </router-link>

      </div>

    </div>
    <el-pagination
        v-show="selectType === 'soon'"
        @size-change="getSoonData"
        @current-change="getSoonData"
        v-if="pagination"
        :hide-on-single-page="true"
        background
        layout="prev, pager, next"
        :page-size="pagination.per_page"
        :total="pagination.total">
    </el-pagination>
    <el-pagination
        v-show="selectType === 'past'"
        @size-change="getPastData"
        @current-change="getPastData"
        v-if="pagination"
        :hide-on-single-page="true"
        background
        layout="prev, pager, next"
        :page-size="pagination.per_page"
        :total="pagination.total">
    </el-pagination>
  </div>
</template>

<script>
  import NoContent from '@/components/NoContent/NoContent'

  export default {
    name: 'Public',
    props: {
      publicData: {
        type: Array,
        default: () => {
          return []
        }
      },
      pagination: {
        type: Object,
        default: () => {
          return {}
        }
      }
    },
    data () {
      return {
        selectType: 'soon',
        noContentData: {
          pic: 'course_pic',
          tip: '您还没有报名课程哦~'
        }
      }
    },
    mounted () {
      this.getSoonData()
    },
    methods: {
      getSoonData (page = 1) {
        this.selectType = 'soon'
        this.$emit('getSoonData', page)
      },
      getPastData (page = 1) {
        this.selectType = 'past'
        this.$emit('getPastData', page)
      },
      translateTag (id) {
        let types = this.$store.state.common.categoryTypes
        let tag = types.find(item => {
          return item.id === id
        })
        return tag.abbr
      }
    },
    components: {
      NoContent
    }
  }
</script>

<style lang="less" scoped>
  /*@import '../../../../assets/style/lib-base.less';*/

  .public {
    .type {
      width: 160px;
      display: flex;
      justify-content: space-around;

      & > div {
        cursor: pointer;
      }
    }

    & > .course-con {
      height: 417px;
      overflow-y: auto;

      & > .item {
        width: 100%;
        height: 138px;
        border-bottom: 1px solid #e6e6e6;

        & > a {
          display: flex;
          align-items: center;
          height: 100%;

          & > .img {
            width: 176px;
            height: 99px;
            margin-right: 20px;
            border-radius: 3px;
            overflow: hidden;

            & > img {
              width: 100%;
              height: 100%;
            }
          }

          & > .course-desc {
            text-align: left;
            width: 75%;

            & > .course-title {
              display: flex;
              align-items: center;

              & > span {
                background: #667482;
                color: #fff;
                padding: 2px 4px;
                margin-right: 8px;
                font-size: @fontSizeCon;
              }

              font-size: @fontSizeTitle;
              color: #333;
              font-weight: bold;
            }

            & > .course-time {
              font-size: @fontSizeSubTitle;
              color: #5A5C63;
              margin: 6px 0 30px;
            }

            & > .course-price {
              color: @papaRed;
              font-size: @fontSizeTitle;
              font-weight: 600;
            }

            & > .course-last-line {
              display: flex;
              justify-content: space-between;

              & > .course-enrolls {
                color: #999;
                font-size: @fontSizeCon;
              }

              & > .call-service {
                background: #EDF9F3;
                color: @papaGreen;
                font-size: @fontSizeCon;
                cursor: pointer;
              }
            }


          }
        }
      }
    }
  }

  .is-active {
    color: @papaGreen;
  }

  .el-pagination {
    margin-top: 20px;
    float: right;
  }
</style>
