<template>
  <div
          class="box"
  >
<!--    <div class="close_btn">-->
<!--      <i class="iconfont icon-papa-close-small"></i>-->
<!--    </div>-->
    <div class="content">
      你的下载次数已超出限制，请使用已下载文件的设备（电脑或手机）查看资料哦！
      如仍需帮助，请联系你的课程助教~
    </div>
    <div class="handler">
      <div class="button" @click="confirmBtn">确认</div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'tips',
    data() {
      return {

      }
    },
    methods: {
      confirmBtn() {
        this.$emit('closePopUp')
      }
    }
  }
</script>

<style lang="less" scoped>
.box{
  width: 440px;
  background: #FFFFFF;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  .content{
    box-sizing: border-box;
    width: 100%;
    padding: 10px 52px;
    color: rgba(0,0,0,0.6);
    text-align: center;
  }
  .handler{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .button{
      width: 120px;
      height: 44px;
      background: #29D087;
      border-radius: 30px 30px 30px 30px;
      opacity: 1;
      text-align: center;
      line-height: 44px;
      font-size:16px;
      color: #fff;
      cursor: pointer;
    }
  }
}
</style>
