import { render, staticRenderFns } from "./PublicMaterial.vue?vue&type=template&id=18cea0fa&scoped=true"
import script from "./PublicMaterial.vue?vue&type=script&lang=js"
export * from "./PublicMaterial.vue?vue&type=script&lang=js"
import style0 from "./PublicMaterial.vue?vue&type=style&index=0&id=18cea0fa&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18cea0fa",
  null
  
)

export default component.exports