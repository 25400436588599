import * as Api from '@/api/user/user'
import PublicCourse from './Public/Public.vue'
import Community from './Community/Community.vue'
import PapaCalendar from '@/components/Calendar/Calendar.vue'
import PaidCourse from '@/views/User/MyCourse/PaidCourse/PaidCourse.vue'

export default {
  components: {
    PublicCourse,
    PapaCalendar,
    Community,
    PaidCourse
  },
  data () {
    return {
      activeName: 'publicCourse',
      publicData: [],
      pagination: {},
      liveCourseData: [],
      videoCourseData: [],
      historyCourseData: [],
      coursePagination: {},
      courseParams: {},
      isCalendar: false,
      calendarData: [],
      viewType: 'paid',
      // isShowCommunityTab: true,
      isJudge: true
    }
  },
  mounted () {
  },
  methods: {
    getSoonData (page) {
      if (typeof page !== 'number') {
        page = 1
      }
      let params = {
        page: page
      }
      Api.publicCourse(params, (res, page) => {
        this.publicData = res
        this.pagination = page
      })
    },
    getPastData (page = 1) {
      if (typeof page !== 'number') {
        page = 1
      }
      let params = {
        page: page
      }
      Api.publicCourseHistory(params, (res, page) => {
        this.publicData = res
        this.pagination = page
      })
    },
    getCourseData (page) {
      let params = this.courseParams
      params.page = page
      Api.userCourse(params, (res, pagination) => {
        if (this.activeName === 'live') {
          this.liveCourseData = res
        } else if (this.activeName === 'video') {
          this.videoCourseData = res
        } else if (this.activeName === 'history') {
          this.historyCourseData = res
        }
        this.coursePagination = pagination
      })
    },
    handleTabClick (item) {
      let data = {}
      switch (item.name) {
        case 'live':
          data = {
            course_mode: this.constant.COURSE_MODEL.LIVE,
            course_type: this.constant.COURSE_TYPE.LIVE_FEE
          }
          break
        case 'video':
          data = {
            course_mode: this.constant.COURSE_MODEL.VIDEO,
            course_type: this.constant.COURSE_TYPE.LIVE_FEE
          }
          break
        case 'history':
          data = {
            is_expired: 1
          }
          break
        default :
          break
      }
      this.courseParams = {...data}
      this.getCourseData(1)
    },
    changeView () {
      this.isCalendar = !this.isCalendar
    },
    chooseTab (type) {
      console.log(type)
      this.viewType = type
    },
    judgeTab (view) {
      console.log(view)
      this.viewType = view
      if (view === 'paid') {
        this.isShowCommunityTab = false
      } else if (view === 'community') {
        this.isShowCommunityTab = true
        this.isJudge = false
      }
    }
  }
}
