import * as Api from '@/api/user/user'
import { formatMoment, translateTag } from '@/utils/utils'
import { mapActions } from 'vuex'
import { COURSE_MODEL } from '@/enums/API'
import PapaPagination from '@/components/Pagination/Pagination'
import PublicMaterial from '@/views/User/MyCourse/PublicMaterial/PublicMaterial.vue'
import NoContent from '@/components/NoContent/NoContent'


export default {
  data () {
    return {
      privateCourseData: [],
      courseData: [],
      liveCourseData: [],
      videoCourseData: [],
      videoPagination: {},
      livePagination: {},
      pagination: {},
      privatePagination: {},
      classPagination: {},
      viewType: 'class',
      noContentData: {
        pic: 'course_pic',
        tip: '您还没有此分类课程哦~'
      }
    }
  },
  mounted () {
    this.getClassCourse(1,1)
    this.getClassCourse(1,3)
  },
  methods: {
    getPrivateCourse ( page = 1 ) {
      let params = {
        page: page,
      }
      Api.userCourse(params, ( res, pagination ) => {
        this.privateCourseData = res
        this.privatePagination = pagination

      }, '', 'v1')
    },
    getClassCourse ( page = 1, mode ) {
      let params = {
        course_mode: mode,
        is_expired: 0,
        page: page
      }
      Api.userCourseClass(params, ( res, pagination ) => {
        if(mode === 1){
          this.liveCourseData = res
          this.livePagination = pagination
        }else{
          this.videoCourseData = res
          this.videoPagination = pagination
        }
        // this.courseData = res
        // this.classPagination = pagination
        this.pagination = pagination
        this.getPrivateCourse(1)
      }, '', 'v1')
    },
    changeView ( type ) {
      this.viewType = type
      if (type === 'class') {
        this.pagination = this.livePagination
      }else if(type === 'video'){
        this.pagination = this.videoPagination
      } else {
        this.pagination = this.privatePagination
      }
      // this.handlePagination(1)
    },
    goPrivate ( item ) {
      this.setCurrentCourse(item)
      sessionStorage.currentCourseDesc = JSON.stringify(item)
      this.$router.push({
        path: `/liveDetail/private/${item.id}`,
      })
    },
    goClassCourse ( item ) {
      this.setCurrentCourse(item)
      sessionStorage.currentCourseDesc = JSON.stringify(item)
      if (item.course.course_mode === COURSE_MODEL.LIVE) {
        this.$router.push({
          path: `/liveDetail/class/${item.id}`,
        })
      } else {
        this.$router.push({
          path: `/user/classCourseDetail/${item.id}/${item.course.id}`,
        })
      }
    },
    handlePagination ( pagination ) {
      console.log(this.viewType)
      if (this.viewType === 'class' || this.viewType == 'video') {
        this.getClassCourse(pagination,1)
        this.getClassCourse(pagination,3)
      } else {
        this.getPrivateCourse(pagination)
      }
    },
    formatMoment,
    translateTag,
    ...mapActions([
      'setCurrentCourse'
    ])
  },
  components: {
    PapaPagination,
    PublicMaterial,
    NoContent
  }
}
