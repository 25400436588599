<template>
  <div class="course">
    <div class="course-con" v-if="courseData.length>0">
      <div class="item" v-for="(item,index) in courseData" :key="index">
        <div class="item-con" v-if="!item.can_enter">
          <div class="mask"></div>
          <div class="detail">
            <div class="img">
              <img :src="item.course.cover_image_url" alt="">
            </div>
            <div class="course-desc">
              <div class="course-title">
                <span
                    v-if="translateTag(item.course.category_id).abbr!=undefined">{{translateTag(item.course.category_id).abbr}}</span>
                {{item.course.title}}
              </div>
              <div class="course-time">
               有效期至 {{item.subtitle}}
              </div>
              <!--          <div class="course-last-line">-->
              <!--            <div class="course-enrolls">{{item.course.enrolls}}人已报名</div>-->
              <!--            <div class="call-service">请联系微信小助手参与课程</div>-->
              <!--          </div>-->
            </div>
          </div>
        </div>
        <router-link v-else :to="{path:`/user/pastCourseDetail/${item.course.id}`}">
          <div class="img">
            <img :src="item.course.cover_image_url" alt="">
          </div>
          <div class="course-desc">
            <div class="course-title">
              <span
                  v-if="translateTag(item.course.category_id).abbr!=undefined">{{translateTag(item.course.category_id).abbr}}</span>
              {{item.course.title}}
            </div>
            <div class="course-time">
              有效期至{{forma}}
            </div>
          </div>
        </router-link>
      </div>
    </div>
    <NoContent v-else :data="noContentData"></NoContent>
    <el-pagination
        @size-change="getCourseData"
        @current-change="getCourseData"
        v-if="coursePagination&&courseData.length>0"
        :hide-on-single-page="true"
        background
        layout="prev, pager, next"
        :page-size="coursePagination.per_page"
        :total="coursePagination.total">
    </el-pagination>
  </div>
</template>

<script>
  import NoContent from '@/components/NoContent/NoContent.vue'
  import { formatMoment, translateTag } from '@/utils/utils'

  export default {
    name: 'Public',
    props: {
      courseData: {
        type: Array,
        default: () => {
          return []
        }
      },
      coursePagination: {
        type: Object,
        default: () => {
          return {}
        }
      }
    },
    data () {
      return {
        params: {},
        noContentData: {
          pic: 'course_pic',
          tip: '您还没有报名课程哦~'
        }
      }
    },
    computed: {},
    mounted () {
      // this.courseData = []
      console.log(this.courseData)
      this.getCourseData()
    },
    methods: {
      getCourseData (page = 1) {
        this.$emit('getCourseData', page, this.params)
      },
      getPastData (page = 1) {
        this.$emit('getPastData', page)
      },
      translateTag,
      formatMoment
    },
    components: {
      NoContent
    }
  }
</script>

<style lang="less" scoped>
  /*@import '../../../../assets/style/lib-base.less';*/

  .course {
    & > .course-con {
      /*height: 417px;*/
      overflow-y: auto;

      & > .item {

        & > a {
          width: 100%;
          height: 138px;
          display: flex;
          align-items: center;
          cursor: pointer;

          & > .img {
            width: 176px;
            height: 99px;
            margin-right: 20px;
            border-radius: 3px;
            overflow: hidden;

            & > img {
              width: 100%;
              max-height: 99px;
            }
          }

          & > .course-desc {
            text-align: left;
            width: 75%;

            & > .course-title {
              display: flex;
              align-items: center;

              & > span {
                background: #667482;
                color: #fff;
                padding: 2px 4px;
                margin-right: 8px;
                font-size: @fontSizeCon;
              }

              font-size: @fontSizeTitle;
              color: #333;
              font-weight: bold;
            }

            & > .course-time {
              font-size: @fontSizeSubTitle;
              color: #5A5C63;
              margin: 6px 0 30px;
            }

            & > .course-price {
              color: @papaRed;
              font-size: @fontSizeTitle;
              font-weight: 600;
            }

            & > .course-last-line {
              display: flex;
              justify-content: space-between;

              & > .course-enrolls {
                color: #999;
                font-size: @fontSizeCon;
              }

              & > .call-service {
                background: #EDF9F3;
                color: @papaGreen;
                font-size: @fontSizeCon;
                cursor: pointer;
              }
            }


          }
        }

        & > .item-con {
          position: relative;

          & > .mask {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(247, 247, 247, 0.3);
          }

          & > .detail {
            width: 100%;
            height: 138px;
            border-bottom: 1px solid #e6e6e6;
            display: flex;
            align-items: center;
            cursor: pointer;

            & > .img {
              width: 176px;
              height: 99px;
              margin-right: 20px;
              border-radius: 3px;
              overflow: hidden;

              & > img {
                width: 100%;
                height: 100%;
              }
            }

            & > .course-desc {
              text-align: left;
              width: 75%;

              & > .course-title {
                display: flex;
                align-items: center;

                & > span {
                  background: #667482;
                  color: #fff;
                  padding: 2px 4px;
                  margin-right: 8px;
                  font-size: @fontSizeCon;
                }

                font-size: @fontSizeTitle;
                color: #333;
                font-weight: bold;
              }

              & > .course-time {
                font-size: @fontSizeSubTitle;
                color: #5A5C63;
                margin: 6px 0 30px;
              }

              & > .course-price {
                color: @papaRed;
                font-size: @fontSizeTitle;
                font-weight: 600;
              }

              & > .course-last-line {
                display: flex;
                justify-content: space-between;

                & > .course-enrolls {
                  color: #999;
                  font-size: @fontSizeCon;
                }

                & > .call-service {
                  background: #EDF9F3;
                  color: @papaGreen;
                  font-size: @fontSizeCon;
                  cursor: pointer;
                }
              }


            }
          }

        }
      }

      a:hover {

        background: #F4FDF8;
      }
    }
  }

  .is-active {
    color: @papaGreen;
  }

  .el-pagination {
    margin-top: 20px;
    float: right;
  }
</style>
